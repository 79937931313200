import React, { useEffect } from "react";
import { supportLanguageList } from "../../constants/locale";

import "./index.css";
import CloseIcon from "../../assets/images/close-icon.svg";

export const LangModal = ({ visible, onClose, onSelectLang, lang, title }) => {
  useEffect(() => {
    // 防止滚动穿透
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible]);

  return (
    <div className={`lng-modal ${visible ? "visible" : ""}`}>
      <div className="header">
        <div className="wrapper">
          <div>{title}</div>
          <img className="close" src={CloseIcon} onClick={onClose} />
        </div>
      </div>

      <div className="content">
        <ul className="modal-list">
          {supportLanguageList.map((item) => (
            <li
              key={item.locale}
              className={`modal-item ${lang === item?.locale ? "active" : ""}`}
              onClick={() => onSelectLang?.(item.locale ?? "en")}
            >
              {item.languageName}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
