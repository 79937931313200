import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackEnd from "i18next-http-backend";
import { supportLanguageList } from "./constants/locale";

const supportLngs = supportLanguageList.map((i) => i.locale);

i18n
  .use(HttpBackEnd)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    supportLngs,
    interpolation: {
      escapeValue: false,
    },
    ns: ["homepage", "common"],
    backend: {
      loadPath: (lngs) => {
        try {
          const lng = lngs[0];
          const item = supportLanguageList.find((i) => i.locale === lng);
          return `/locales/${item.languageKey}.json`;
        } catch {
          return `/locales/en_US.json`;
        }
      },
    },
  });

export default i18n;
