import React, { useEffect } from "react";
import "./index.css";

export const Toast = ({ message, duration = 2000, onClose }) => {
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        onClose?.();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [message, duration, onClose]);

  if (!message) {
    return null;
  }

  return (
    <div className="toast" onClick={onClose}>
      {message}
    </div>
  );
};
