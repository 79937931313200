import React, { useEffect, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Toast } from "./components/Toast";
import { LangModal } from "./components/LangModal";
import { isMobile } from "./utils";
import { specifiedImageLocales } from "./constants/locale";

import "./App.css";
import MainImg from "./assets/images/main.png";
import SavingImg from "./assets/images/saving.png";
import DefaultRewardImg from "./assets/images/rewards/en_US-rewards.png";
import GlobalIcon from "./assets/images/global.svg";
import AppIcon from "./assets/images/app-icon.svg";

const LocalePage = () => {
  const { locale } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState(DefaultRewardImg);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    if (locale && i18n?.language !== locale) {
      i18n.changeLanguage(locale);
      switchImg(locale);
    }
  }, [locale]);

  const switchImg = (locale) => {
    if (locale && specifiedImageLocales.find((i) => i.locale === locale)) {
      const loadImg = async () => {
        try {
          const path = specifiedImageLocales.find(
            (i) => i.locale === locale
          ).languageKey;
          const imgModule = await import(
            `./assets/images/rewards/${path}-rewards.png`
          );
          setImgSrc(imgModule.default);
        } catch {
          setImgSrc(DefaultRewardImg);
        }
      };
      loadImg();
    } else {
      setImgSrc(DefaultRewardImg);
    }
  };

  const onSelectLang = (lng) => {
    i18n.changeLanguage(lng);
    switchImg(lng);
    navigate(`/${lng}`);
    setVisible(false);
  };

  const onClickDownload = () => {
    if (isMobile() && ONELINK_RESULT.clickURL) {
      // 手机端访问：跳转到下载链接
      window.location.href = ONELINK_RESULT.clickURL;
    } else {
      // 非手机端访问：展示 toast
      setToastMessage(t("homepage.totast_text"));
    }
  };

  return (
    <div className="app">
      <div className="global-banner">
        <img className="global-icon" src={GlobalIcon} onClick={() => setVisible(true)} />
      </div>

      <div className="container">
        <div className="main">
          <div className="title">{t("homepage.title")}</div>
          <div className="subtitle">{t("homepage.sub_text")}</div>
          <img className="main-img" src={MainImg} />
        </div>

        <div className="feature">
          <div className="title">{t("homepage.main_name")}</div>

          <div className="content">
            <div>
              <span className="feat">{`${t("homepage.main_label1")}: `}</span>
              <span className="desc">{t("homepage.main_text1")}</span>
            </div>
            <div>
              <span className="feat">{`${t("homepage.main_label2")}: `}</span>
              <span className="desc">{t("homepage.main_text2")}</span>
            </div>
            <div>
              <span className="feat">{`${t("homepage.main_label3")}: `}</span>
              <span className="desc">{t("homepage.main_text3")}</span>
            </div>
          </div>
        </div>

        <div className="signup">
          <div className="title">{t("homepage.sign_text")}</div>
          <img className="signup-img" src={imgSrc} onError={() => setImgSrc(defa)} />
        </div>

        <div className="saving">
          <div className="subtitle">{t("homepage.saving_text1")}</div>
          <div className="title">{t("homepage.saving_text2")}</div>
          <div className="desc">{t("homepage.saving_text3")}</div>
          <img className="saving-img" src={SavingImg} />
        </div>
      </div>

      <div className="download-banner">
        <div className="title-wrapper">
          <img className="app-icon" src={AppIcon} />
          <div>
            <div className="title">{t("homepage.download_text1")}</div>
            <div className="sub-title">{t("homepage.download_text2")}</div>
          </div>
        </div>

        <div className="button" onClick={onClickDownload}>
          {t("homepage.download_but")}
        </div>
      </div>

      <LangModal
        title={t("homepage.language_title")}
        lang={i18n.language}
        visible={visible}
        onClose={() => setVisible(false)}
        onSelectLang={onSelectLang}
      />

      <Toast
        duration={2000}
        message={toastMessage}
        onClose={() => setToastMessage("")}
      />
    </div>
  );
};

const App = () => {
  const { i18n } = useTranslation();

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/:locale" Component={LocalePage} />
          <Route path="/" Component={LocalePage} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
};

export default App;
