export const supportLanguageList = [
  {
    locale: "en",
    languageKey: "en_US",
    languageName: "English",
  },
  {
    locale: "asia",
    languageKey: "ko_KR",
    languageName: "English (Asia)",
  },
  {
    locale: "ja",
    languageKey: "ja_JP",
    languageName: "日本語",
  },
  // 越南
  {
    locale: "vi",
    languageKey: "vi_VN",
    languageName: "Tiếng Việt",
    hasImg: true,
  },
  // 俄语
  {
    locale: "ru",
    languageKey: "ru_RU",
    languageName: "Русский",
  },
  // 西班牙
  {
    locale: "es",
    languageKey: "es_ES",
    languageName: "Español",
    hasImg: true,
  },
  // 土耳其
  {
    locale: "tr",
    languageKey: "tr_TR",
    languageName: "Türkçe",
  },
  // 意大利语
  {
    locale: "it",
    languageKey: "it_IT",
    languageName: "Italiano",
  },
  // 法语
  {
    locale: "fr",
    languageKey: "fr_FR",
    languageName: "Français",
  },
  // 德语
  {
    locale: "de",
    languageKey: "de_DE",
    languageName: "Deutsch",
  },
  {
    locale: "zh-CN",
    languageKey: "zh_CN",
    languageName: "简体中文",
    hasImg: true,
  },
  // 新繁体
  {
    locale: "zh-TC",
    languageKey: "zh_TW",
    languageName: "繁體中文",
  },
  // 葡萄牙
  {
    locale: "pt",
    languageKey: "pt_BR",
    languageName: "Português (Brasil)",
    hasImg: true,
  },
  // 印尼
  {
    locale: "id",
    languageKey: "in_ID",
    languageName: "Bahasa Indonesia",
  },
  {
    locale: "th",
    languageKey: "th_TH",
    languageName: "ไทย",
  },
  {
    locale: "pl",
    languageKey: "pl_PL",
    languageName: "Polski",
  },
  {
    locale: "uk",
    languageKey: "uk_UA",
    languageName: "українська",
  },
  // 葡萄牙 (欧语)
  {
    locale: "pt-PT",
    languageKey: "pt_PT",
    languageName: "Português (Portugal)",
  },
  // 西班牙(欧语)
  {
    locale: "es-ES",
    languageKey: "es_AR",
    languageName: "Español (Europa)",
  },
  {
    locale: "uz",
    languageKey: "uz_UZ",
    languageName: "o'zbek",
  },
  // 阿拉伯
  {
    locale: "ar",
    languageKey: "ar_SA",
    languageName: "العربية",
  },
  // 菲律宾
  {
    locale: "ph",
    languageKey: "ph_PH",
    languageName: "Filipino",
  },
];

/**
 * 特殊指定图片的国家
 */
export const specifiedImageLocales = supportLanguageList.filter(
  (i) => i?.hasImg
);
